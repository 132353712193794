

























































































import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  props: [],
  data() {
    return {
      selfDisabled: false,
      tempDisabled: false,
    };
  },
  computed: {
    attrs() {
      if (!this.options.content.attrsMaker) {
        return this.options.attrs;
      }
      return this.options.content.attrsMaker(this.options.attrs, this);
    },
    text() {
      if (!this.options.content.text) {
        return;
      } else if (typeof this.options.content.text === "function") {
        return this.options.content.text(this);
      }
      return this.options.content.text;
    },
    icon() {
      if (!this.options.content.icon) {
        return;
      } else if (typeof this.options.content.icon === "function") {
        return this.options.content.icon(this);
      }
      return this.options.content.icon;
    },
    disabledState() {
      if (this.enabled) {
        return !this.enabled.value;
      }
      if (this.disabled) {
        return this.disabled.value;
      }
      return this.selfDisabled;
    },
  },
  methods: {
    initialize() {},
    setDisabled(value: boolean) {
      if (this.enabled) {
        this.enabled.value = !value;
      } else if (this.disabled) {
        this.disabled.value = value;
      } else {
        this.selfDisabled = value;
      }
    },
  },
  extraOptions: {
    defaultType: "v-btn",
    defaultClass: "btn",
    forwardEvents: {
      click: "xClick",
    },
    forwardStates: {
      xEnabled: ["enabled", false, true],
      xDisabled: ["disabled", false, true],
      xLoading: ["loading", false],
    },
  },
});
